<template>
  <b-container fluid>
    <bo-page-title />
    <section id="invrelate">
      <b-tabs v-if="isList">
        <b-tab title="Page Settings">
            <bo-card title="Hero Image">
                <b-row>
                <b-col md="4">
                    <bo-card-img title="Hero RUPS Information" :src="uploader(heroImage.ssf_file, '/assets/images/no-slide.jpg')">
                    <template #buttons>
                        <b-button
                        variant="secondary"
                        size="sm"
                        pill
                        class="btn-icon"
                        v-b-tooltip.hover="'Update'"
                        @click="changeHero()"
                        v-if="moduleRole('updateHero')"
                        >
                        <i class="fas fa-pencil-alt" />
                        </b-button>
                    </template>
                    </bo-card-img>
                </b-col>
                </b-row>

                <b-modal 
                v-model="heroEditorOpen"
                :title="'Update Hero Image '+ pageTitle"
                size="lg"
                hide-footer
                body-class="p-0"
                >
                <validation-observer
                    v-slot="{ handleSubmit }" ref="VFormHero"
                >
                    
                    <b-form @submit.prevent="handleSubmit(submitHero)">
                    <b-card-body>
                        <b-row>
                        <b-col md="6">
                            <div class="file-uploader">
                            <label>Hero Image Desktop: </label>
                            <Uploader v-model="heroImage.ssf_file" type="hero-more-info"/>
                            <VValidate 
                                name="Hero Image Desktop" 
                                v-model="heroImage.ssf_file"
                                :rules="validationHeroImage.ssf_file" 
                            />
                            </div>
                            
                            <div class="file-uploader">
                            <label>Hero Image Mobile: </label>
                            <Uploader v-model="heroImage.ssf_file_mobile" type="hero-more-info-mobile" use-preview />
                            <VValidate 
                                name="Hero Image Mobile" 
                                v-model="heroImage.ssf_file_mobile"
                                :rules="validationHeroImage.ssf_file_mobile" 
                            />
                            </div>
                        </b-col>
                        <b-col md="6">
                            <b-card class="border mb-0">
                            <template #header>
                                <h5 class="card-title">Content (ID)</h5>
                            </template>
                            <b-form-group label-for="heroTitleId">
                                <template #label>Title <strong class="text-danger">*</strong></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="heroTitleId" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')"/>
                                <VValidate 
                                name="Content ID" 
                                v-model="heroImage.ssf_title_id"
                                :rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                            </b-form-group>

                            <b-form-group label-for="altId">
                                <template #label>Alt Image <strong class="text-danger">*</strong></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altId" v-model="heroImage.ssf_alt_id" />
                                <VValidate 
                                name="Alt Image Id" 
                                v-model="heroImage.ssf_alt_id"
                                :rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                            </b-form-group>
                            
                            <b-form-group label-for="altIdM">
                              <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altIdM" v-model="heroImage.ssf_alt_mobile_id" />
                                <VValidate 
                                  name="Alt Image Mobile ID" 
                                  v-model="heroImage.ssf_alt_mobile_id"
                                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                              />
                            </b-form-group>
                            
                            </b-card>
                            <b-card class="border mb-0">
                            <template #header>
                                <h5 class="card-title">Content (EN)</h5>
                            </template>
                            <b-form-group label-for="heroTitleEn">
                                <template #label>Title <strong class="text-danger">*</strong></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="heroTitleEn" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en"/>
                                <VValidate 
                                name="Content EN" 
                                v-model="heroImage.ssf_title_en"
                                :rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                            </b-form-group>

                            <b-form-group label-for="altEN">                          
                                <template #label>Alt Image <strong class="text-danger">*</strong></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altEN" v-model="heroImage.ssf_alt_en" />
                                <VValidate 
                                name="Alt Image En" 
                                v-model="heroImage.ssf_alt_en"
                                :rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                            </b-form-group>

                            <b-form-group label-for="altEnM">
                              <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                              <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altIEn" v-model="heroImage.ssf_alt_mobile_en" />
                              <VValidate 
                                name="Alt Image Mobile EN" 
                                v-model="heroImage.ssf_alt_mobile_en"
                                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                              />
                            </b-form-group>
                            </b-card>
                        </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer>
                        <div class="text-right">
                        <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
                        </div>
                    </b-card-footer>
                    </b-form>
                </validation-observer>
                </b-modal>
            </bo-card>
          
            <b-card no-body>
                <b-card-header>
                    <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0">Content Posts</h5>
                        <div v-if="Object.keys(dtCounter).length" class="ml-3">
                            <b-button class="btn-rounded" size="sm" 
                                :variant="filterByStatus=='all'?'info':'outline-info'"
                                :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, filterByStatus:'all'})}"
                            >All <span>({{ dtCounter.all||0 }})</span>
                            </b-button>
                            <b-button class="btn-rounded ml-1" size="sm" 
                                :variant="filterByStatus=='published'?'info':'outline-info'"
                                :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, filterByStatus:'published'})}"
                            >Published <span>({{ dtCounter.published||0 }})</span>
                            </b-button>
                            <b-button class="btn-rounded ml-1" size="sm" 
                                :variant="filterByStatus=='scheduled'?'info':'outline-info'"
                                :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, filterByStatus:'scheduled'})}"
                            >Scheduled <span>({{ dtCounter.scheduled||0 }})</span></b-button>
                            <b-button class="btn-rounded ml-1" size="sm" 
                                :variant="filterByStatus=='draft'?'info':'outline-info'"
                                :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, filterByStatus:'draft'})}"
                            >Draft <span>({{ dtCounter.draft||0 }})</span></b-button>
                            <b-button class="btn-rounded ml-1" size="sm" 
                                :variant="filterByStatus=='inactive'?'info':'outline-info'"
                                :to="{name:$route.name, query:Object.assign({}, this.$route.query||{}, {page:1, filterByStatus:'inactive'})}"
                            >Inactive <span>({{ dtCounter.inactive||0 }})</span></b-button>
                        </div>
                    </div>
                </b-card-header>

                <b-card-header class="border-top">
                    <b-row>
                        <b-col md="8">
                            <b-form-row>
                                <b-col md="auto">
                                    <b-button-group>
                                        <b-button
                                            @click="viewMode = 'list'"
                                            v-b-tooltip.hover title="List View"
                                            :variant="viewMode == 'list' ? 'info' : ''"
                                        >
                                            <i class="fas fa-list"></i>
                                        </b-button>
                                        <b-button
                                            @click="viewMode = 'grid'"
                                            v-b-tooltip.hover title="Grid View"
                                            :variant="viewMode == 'grid' ? 'info' : ''"
                                        >
                                            <i class="fas fa-th"></i>
                                        </b-button>
                                    </b-button-group>
                                </b-col>
                            </b-form-row>
                        </b-col>
                        <b-col md="4">
                            <b-form @submit.prevent="doFilter()" class="d-flex">
                                <b-input-group>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
                                </b-input-group-append>
                                </b-input-group>
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    @click="doReset()"
                                >Reset</b-button>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-card-header>
                
                <template v-if="viewMode == 'list'">
                    <b-table
                        :fields="fields"
                        :items="data.data||[]"
                        :per-page="perPage"
                        :primary-key="idKey"
                        :busy="!data.data"
                        responsive
                        show-empty
                        striped
                        bordered
                    >

                        <template #empty>
                        <div class="text-center">
                            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                            <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                        </div>
                        </template>
                        <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                        </template>
                        <template #cell(number)="v">
                        {{(data.per_page*(data.current_page-1))+v.index+1}}
                        </template>

                        <template #cell(mil_title_id)="data">
                        <div class="wrap_title_post">
                            <h3>
                            <!--:href="foLink(data.item)" target="_blank"-->
                            <a href="javascript:;">
                                {{ data.value }}
                            </a>
                            </h3>
                            <ul>
                            <li><i class="icon-user"></i> {{ data.item.mil_author }}</li>
                            <li><i class=" ti-calendar"></i> {{ data.item.mil_publish_date | moment("LL") }}</li>
                            </ul>
                        </div>
                        </template>

                        <template
                        #cell(mil_is_active) = "data"
                        >
                            <b-badge v-if="data.item.mil_is_active=='P'" variant="success" :class="{'post-block__badge':viewMode=='grid'}">Published</b-badge>
                            <b-badge v-else-if="data.item.mil_is_active=='D'" variant="secondary" :class="{'post-block__badge':viewMode=='grid'}">Draft</b-badge>
                            <b-badge v-else-if="data.item.mil_is_active=='N'" variant="danger" :class="{'post-block__badge':viewMode=='grid'}">Not Published</b-badge>
                            <b-badge v-else-if="data.item.mil_is_active=='S'" variant="info" :class="{'post-block__badge':viewMode=='grid'}">Scheduled</b-badge>
                        </template>

                        <template
                        #cell(action)="data"
                        >
                        <b-button
                            v-if="moduleRole('edit')"
                            v-b-tooltip.hover
                            title="Edit"
                            class="btn-icon"
                            variant="outline-warning"
                            size="sm"
                            :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                        >
                            <i class="fas fa-pencil-alt"></i>
                        </b-button>
                        <b-button
                            v-if="moduleRole('delete')"

                            v-b-tooltip.hover
                            title="Delete"
                            class="btn-icon"
                            variant="outline-danger"
                            size="sm"
                            @click="doDelete(data.index, data.item)"
                        >
                            <i class="fas fa-trash-alt"></i>
                        </b-button>
                        </template>

                    </b-table>
                </template>
                <template v-else>                                    
                    <b-card-body>
                    <template v-if="!data.data">
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </template>
                    <template v-else-if="(data.data||[]).length">
                        <b-row cols="1" cols-md="2" cols-lg="4" class="gutter-2">
                        <b-col
                            v-for="(item,key) in (data.data||[])" 
                            :key="key"
                        >
                            <div class="post-block">
                            <div class="post-block-media text-center">
                                <b-img
                                fluid-grow
                                class="post-block__img"
                                :src="uploader(item.mil_img)"
                                />
                                <b-badge v-if="item.mil_is_active=='P'" variant="success" :class="'mt-2 grid'">Published</b-badge>
                                <b-badge v-else-if="item.mil_is_active=='D'" variant="secondary" :class="'mt-2 grid'">Draft</b-badge>
                                <b-badge v-else-if="item.mil_is_active=='N'" variant="danger" :class="'mt-2 grid'">Not Published</b-badge>
                                <b-badge v-else-if="item.mil_is_active=='S'" variant="info" :class="'mt-2 grid'">Scheduled</b-badge>
                                <div class="post-block-actions">
                                <b-button class="btn-icon" size="sm" variant="warning"
                                    v-if="moduleRole('edit')"
                                    :to="{name: $route.name, params: {pageSlug: item[idKey]}}"
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </b-button>
                                <b-button class="btn-icon" size="sm" variant="danger"
                                    v-if="moduleRole('delete')"
                                    @click="doDelete(key, item)"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </b-button>
                                </div>
                            </div>
                            <div class="post-block-body">
                                <h4 class="font-weight-bold post-block__title">{{ item.mil_title_id }}</h4>
                                <span class="post-block__meta">
                                <i class="icon-user"></i> {{ item.mil_author }}
                                </span>
                                <span class="post-block__meta">
                                <i class="ti-calendar"></i> {{ item.mil_publish_date | moment('LL') }}
                                </span>
                            </div>
                            </div>
                        </b-col>
                        </b-row>
                    </template>
                    <template v-else>
                        <h4 align="center"><span v-if="Object.keys(filterData).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                    </template>
                    </b-card-body>
                </template>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
                  <b-pagination
                  class="mb-0"
                  v-model="pageNo"
                  :per-page="data.per_page"
                  :total-rows="data.total"
                  />
                </b-card-footer>
            </b-card>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">RUPS Information SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id" />
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en" />
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id" />
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en" />
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
      <template v-else>
      <validation-observer
        ref="VForm"
        >
        <b-form @submit.prevent="doSubmitInfo">
            <b-tabs v-model="tabIndex">
            <b-tab title="Article Content">
                <b-card no-body>
                <b-card-body>
                    <b-row>
                    <b-col lg="9">
                        <b-card no-body class="border">
                        <b-tabs card v-model="tabIndexContent">
                            <b-tab title="Content (ID)">
                            <b-form-group label-for="articleTitle">
                                <template #label>Title<span class="text-danger mr5">*</span></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="articleTitle" v-model="row.mil_title_id" placeholder="e.g Yearly Report MTWI" />
                                <VValidate 
                                name="Title ID" 
                                v-model="row.mil_title_id" 
                                :rules="{...mrValidation.mil_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                            </b-form-group>
                            <b-form-group label-for="articleDescription">
                                <template #label>Description <span class="text-danger mr5">*</span></template>
                                <CKEditor 
                                id="articleDescription"
                                :value.sync="row.mil_desc_id"
                                :customToolbar="itemsToolbar"
                                />
                                <VValidate 
                                name="Description ID" 
                                v-model="row.mil_desc_id" 
                                :rules="mrValidation.mil_desc_id" 
                                />
                            </b-form-group>
                            </b-tab>
                            <b-tab title="Content (EN)">
                            <b-form-group label-for="articleTitle">
                                <template #label>Title<span class="text-danger mr5">*</span></template>
                                <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="articleTitle" v-model="row.mil_title_en" placeholder="e.g Indonesia has many culture" />
                                <VValidate 
                                name="Title EN" 
                                v-model="row.mil_title_en" 
                                :rules="mrValidation.mil_title_en" 
                                />
                            </b-form-group>
                            <b-form-group label-for="articleDescription">
                                <template #label>Description <span class="text-danger mr5">*</span></template>
                                <CKEditor 
                                id="articleDescription"
                                :value.sync="row.mil_desc_en"
                                :customToolbar="itemsToolbar"
                                />
                                <VValidate 
                                name="Description EN" 
                                v-model="row.mil_desc_en" 
                                :rules="mrValidation.mil_desc_en" 
                                />
                            </b-form-group>
                            </b-tab>
                        </b-tabs>
                        </b-card>
                    </b-col>

                    <b-col lg="3">
                        <b-row>
                        <b-col lg="12" class="mb-2">
                            <b-form-group>
                            <template #label>File <span class="text-danger ml5">*</span></template>
                            <Uploader v-model="row.mil_file" type="rpus" no-preview/>
                            <VValidate 
                                name="File" 
                                v-model="row.mil_file"
                                :rules="mrValidation.mil_file" 
                            />
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" class="mb-2">
                            <b-form-group>
                            <template #label>Cover <span class="text-danger ml5">*</span></template>
                            <Uploader v-model="row.mil_img" type="blog-content"/>
                            <VValidate 
                                name="Cover" 
                                v-model="row.mil_img"
                                :rules="mrValidation.mil_img" 
                            />
                            </b-form-group>
                        </b-col>                                              

                        <b-col lg="12" class="mb-2">
                            <b-form-group>
                            <label>Alt Image Cover (ID)<span class="text-danger mr5">*</span></label>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mil_alt_id" placeholder="e.g Image title">
                            </b-form-input>
                            <VValidate 
                                name="Alt Image Cover ID" 
                                v-model="row.mil_alt_id" 
                                :rules="{...mrValidation.mil_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" class="mb-2">
                            <b-form-group>
                            <label>Alt Image Cover (EN)<span class="text-danger mr5">*</span></label>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mil_alt_en" placeholder="e.g Image title">
                            </b-form-input>
                            <VValidate 
                                name="Alt Image Cover EN" 
                                v-model="row.mil_alt_en" 
                                :rules="{...mrValidation.mil_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" class="mb-2">
                            <b-form-group label-for="articleAuthor">
                            <template #label>Author<span class="text-danger mr5">*</span></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar"
                                id="articleAuthor"
                                v-model="row.mil_author"
                                placeholder="e.g Administrator"
                            />
                            <VValidate 
                                name="Author" 
                                v-model="row.mil_author" 
                                :rules="{...mrValidation.mil_author, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                            </b-form-group>
                        </b-col>
                        <!--
                        <b-col lg="12" class="mb-2">
                            <b-form-group label-for="articleStatus">
                            <b-form-checkbox
                                id="checkbox-1"
                                name="checkbox-1"
                                v-model="row.is_status_draft"
                            >
                                Save as draft
                            </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        -->
                        <b-col lg="12" class="mb-2">
                            <b-form-group label-for="rpusDate">
                            <template #label>RUPS Date<span class="text-danger mr5">*</span></template>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent" v-model="row.mil_rups_date" placeholder="Select RPUS Date"></datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                                </div>
                            </div>

                            <VValidate                                 
                                name="RUPS Date" 
                                v-model="row.mil_rups_date" 
                                rules="required"
                            />
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" class="mb-2">
                            <b-form-group label-for="articlePubDate">
                            <template #label>Publish Date<span class="text-danger mr5">*</span></template>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent" v-model="row.mil_publish_date" :disabled="row.is_status_draft||false" placeholder="Select Publish Date"></datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                                </div>
                            </div>

                            <VValidate 
                                v-if="!row.is_status_draft"
                                name="Publish Date" 
                                v-model="row.mil_publish_date" 
                                rules="required"
                            />
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" class="h-200">
                            <b-form-group label-cols-md="3" label="Status">
                            <b-badge v-if="blogStatus=='P'" variant="success" class="post-block__badge">Published</b-badge>
                            <b-badge v-else-if="blogStatus=='D'" variant="secondary" class="post-block__badge">Draft</b-badge>
                            <b-badge v-else-if="blogStatus=='S'" variant="info" class="post-block__badge">Scheduled</b-badge>
                            <b-badge v-else variant="light" class="post-block__badge">Not Input Yet</b-badge>
                            </b-form-group>
                            
                            <b-form-group>                   
                            <div class="row">
                              <div class="col-md-3">
                                <div class="custom-control custom-radio">
                                  <input type="radio" id="customRadio1" v-model="row.mil_is_active" value="P"
                                    name="radioRow" class="custom-control-input">
                                  <label class="custom-control-label" for="customRadio1">Active</label>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="custom-control custom-radio">
                                  <input type="radio" id="customRadio2" v-model="row.mil_is_active" value="D"
                                    name="radioRow" class="custom-control-input">
                                  <label class="custom-control-label" for="customRadio2">Draft</label>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="custom-control custom-radio">
                                  <input type="radio" id="customRadio3" v-model="row.mil_is_active" value="N"
                                    name="radioRow" class="custom-control-input">
                                  <label class="custom-control-label" for="customRadio3">Inactive</label>
                                </div>
                              </div>
                              <VValidate name="Status" v-model="row.mil_is_active"
                                :rules="mrValidation.mil_is_active" />
                            </div>
                            </b-form-group>
                        </b-col>
                            
                       
                        </b-row>
                    </b-col>
                    </b-row>
                </b-card-body>
                </b-card>
            </b-tab>

            <b-tab title="SEO Settings">
                <b-card no-body>
                <b-card-header>
                    <b-card-title title-tag="h5">RUPS Information List SEO Settings</b-card-title>
                </b-card-header>
                <b-card-body>
                    <b-row>
                    <b-col lg="6">
                        <b-form-group label-for="homeMetaTitle">
                        <template #label>
                            Meta Title (ID)
                            <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                            <i class="fas fa-question"></i>
                            </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title RUPS Information ID" v-model="row.mil_meta_title_id" />
                        <VValidate 
                            name="Meta Title RUPS Information ID" 
                            v-model="row.mil_meta_title_id" 
                            :rules="{...mrValidation.mil_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group label-for="homeMetaTitle">
                        <template #label>
                            Meta Title (EN)
                            <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                            </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title RUPS Information EN" v-model="row.mil_meta_title_en" />
                        <VValidate 
                            name="Meta Title RUPS Information EN" 
                            v-model="row.mil_meta_title_en" 
                            :rules="{...mrValidation.mil_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                        </b-form-group>
                    </b-col>
                    </b-row>

                    <b-row>
                    <b-col lg="6">
                        <b-form-group label-for="homeMetaDesc">
                        <template #label>
                            Meta Description (ID)
                            <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'"
                            >
                            <i class="fas fa-question"></i>
                            </b-badge>
                        </template>
                        <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="row.mil_meta_description_id" />
                        <VValidate 
                            name="Meta Description RUPS Information ID" 
                            v-model="row.mil_meta_description_id" 
                            :rules="{...mrValidation.mil_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group label-for="homeMetaDesc">
                        <template #label>
                            Meta Description (EN)
                            <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                            </b-badge>
                        </template>
                        <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="row.mil_meta_description_en" />
                        <VValidate 
                            name="Meta Description RUPS Information EN" 
                            v-model="row.mil_meta_description_en" 
                            :rules="{...mrValidation.mil_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                        </b-form-group>
                    </b-col>
                    </b-row>


                    <b-row>
                    <b-col lg="6">
                        <b-form-group label-for="homeMetaTags">
                        <template #label>
                            Meta Keywords (ID)
                            <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                            <i class="fas fa-question"></i>
                            </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mil_meta_keyword_id" :tag-validator="validatorTags"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group label-for="homeMetaTags">
                        <template #label>
                            Meta Keywords (EN)
                            <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                            </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mil_meta_keyword_en" 
                        :tag-validator="validatorTags"/>
                        </b-form-group>
                    </b-col>
                    </b-row>
                </b-card-body>
                </b-card>
            </b-tab>
            </b-tabs>
            <b-card no-body>
            <template #footer>
                <div class="text-right">
                <b-button class="btn-rounded" variant="transparent"  @click="$router.back()">Cancel</b-button>
                <b-button class="btn-rounded" variant="success" type="submit">Save Changes</b-button>
                </div>
            </template>
            </b-card>
        </b-form>
        </validation-observer>

      </template>
    </section>
  </b-container>
</template>
<script>

import GlobalVue from '@/libs/Global.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'
import CKEditor from '@/components/CKEditor'
import Datepicker from 'vuejs-datepicker'


export default {
  extends: GlobalVue,
  
  components: {
    BoCardImg, CKEditor, Datepicker
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      dtCounter: {},
      // perPage: 10,
      idKey:'mil_id',
      statusKey:'mil_is_active',
      viewMode:'list',
      fields:[
        {
          key: 'number',
          label: '#',
        },
        {
          key:'mil_title_id',
          label:'Title',
          //sortable: true
        },
        {
          key:'mil_is_active',
          label:'Status'
        },
        'action'
      ],
      row:{},
      mrValidation:{},
      tabIndex: 0,
      tabIndexContent: 0,
      itemsToolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'uploadImage',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'insertTable','mediaEmbed',
        '|', 
        'outdent', 'indent',
        '|',
        'undo',
        'redo'
      ],
    }
  },
  methods: {
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
    doSubmitInfo(){
      
      if(!this.row.mil_title_id || !this.row.mil_desc_id){
        this.tabIndex = 0        
        this.tabIndexContent = 0
      }
      else if(!this.row.mil_title_en || !this.row.mil_desc_en){
        this.tabIndexContent = 1
        this.tabIndex = 0       
      } 
      else if(!this.row.mil_publish_date || !this.row.mil_author || !this.row.mil_img){
        this.tabIndex = 0   
      }
      else{
        if(!this.row.mil_meta_title_id || !this.row.mil_meta_title_en || !this.row.mil_meta_keyword_id || !this.row.mil_meta_keyword_en ||!this.row.mil_meta_description_id || !this.row.mil_meta_description_en){
          this.tabIndex = 1
        }
      }


      this.$set(this.row, 'blogStatus', this.blogStatus)
      this.doSubmitCRUD('VForm', this.$refs)
    }
  },
  computed:{
    filterData(){
      let filterData = JSON.parse(JSON.stringify(this.filter))
      delete filterData.viewMode
      delete filterData.page
      return filterData
    },
    filterByStatus(){
        return this.$route.query.filterByStatus||"all"
    },
    blogStatus(){
      if(!this.row.mil_publish_date && !this.row.mil_is_active) return ""
      else if(this.row.mil_is_active == "D") return "D"
      else if(this.row.mil_is_active == "N") return "N"
      const dateNow = new Date()
      if(new Date(this.row.mil_publish_date) > dateNow) return "S"
      else return "P"
    }
  },
  watch: {
    $route(){
      this.apiGet()
    },
    'row.mil_title_id'(v){
      if(this.isAdd){
        this.row.mil_meta_title_id = v
        this.row.mil_alt_id = v
      }
    },
    'row.mil_title_en'(v){
      if(this.isAdd){
        this.row.mil_meta_title_en = v
        this.row.mil_alt_en = v
      }
    },
    
    'row.mil_desc_id'(v){
      if(this.isAdd){
        this.row.mil_meta_description_id = v.replace(/<[^>]*>?/gm, '')
      }
    },
    'row.mil_desc_en'(v){
      if(this.isAdd){
        this.row.mil_meta_description_en = v.replace(/<[^>]*>?/gm, '')
      }
    },
    // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },

    'row.mil_meta_title_id'(v){
      let el = document.getElementById('Meta Title RUPS Information ID')
      if(el){
        let showcount = document.getElementById('Meta Title RUPS Information ID' + 'count')
        let cErr = document.getElementById('Meta Title RUPS Information ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"              
          showcount.style.display = "none"        
        }
      }
    },
    'row.mil_meta_title_en'(v){
      let el = document.getElementById('Meta Title RUPS Information EN')
      if(el){
        let showcount = document.getElementById('Meta Title RUPS Information EN' + 'count')
        let cErr = document.getElementById('Meta Title RUPS Information EN' + 'count-err')        
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"              
          showcount.style.display = "none"        
        }
      }
    },
    'row.mil_meta_description_id'(v){
      let el = document.getElementById('Meta Description RUPS Information ID')
      if(el){
        let showcount = document.getElementById('Meta Description RUPS Information ID' + 'count')
        let cErr = document.getElementById('Meta Description RUPS Information ID' + 'count-err')                
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"              
          showcount.style.display = "none"        
        }
      }
     
    },
    'row.mil_meta_description_en'(v){
      let el = document.getElementById('Meta Description RUPS Information EN')
      if(el){
        let showcount = document.getElementById('Meta Description RUPS Information EN' + 'count')
        let cErr = document.getElementById('Meta Description RUPS Information EN' + 'count-err')                
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"             
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"              
          showcount.style.display = "none"        
        }
      }
    }
  }
}
</script>